<template>
  <vx-card :title="pageTitle">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template #thead>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template v-slot="{data}">
            <tbody>
              <vs-tr :data="record" :key="index" v-for="(record, index) in data">
                <vs-td><p class="font-medium">{{ record.name }}</p></vs-td>
                <vs-td>
                  <div class="flex">
                    <vx-tooltip text="Editar">
                      <vs-button
                        :disabled="!($can('update', 'calculator'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                        @click="retrieveEntry(record.id)" @click.stop
                      />
                    </vx-tooltip>
                    <vx-tooltip text="Publicar">
                      <vs-button
                        :disabled="record.in_production" size="large" :color="record.in_production ? 'dark' : 'success'"
                        type="flat" icon-pack="feather" icon="icon-send" @click="confirmCommit(record)" @click.stop
                      />
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import Notifier from "./../../utils/notification";
  import { CommitEntryNotification } from "@/utils/notificationMixins";

  let notifier = new Notifier();
  const calculatorsClient = new CoreClient("calculators");

  export default {
    data() {
      return {
        entries: [],
        queryStrings: {},
        pageTitle: "Calculadoras de préstamos",
      };
    },

    async created() {
      try{
        this.$vs.loading();

        await this.getEntries();
        this.$vs.loading.close();
      } catch (_) {
        this.$vs.loading.close();
        await notifier.notification("error");
      }
    },

    methods: {
      async getEntries(){
        const calculatorsResponse = await calculatorsClient.getCalculators();

        this.entries = calculatorsResponse.data.results;
      },

      confirmCommit(entry){
        CommitEntryNotification.fire().then(async (result) => {
          if (result.value && entry.id){
            try{
              this.$vs.loading();
              await calculatorsClient.commitCalculator({ pathParameters: { entryId: entry.id } });
              await notifier.notification("committed");
              this.$vs.loading.close();
              await this.getEntries();
            } catch(_) {
              this.$vs.loading.close();
              await notifier.alertMessage("error");
            }
          }
        })
      },

      createEntry() {
        this.$router.push({name: "create-calculator"});
      },

      retrieveEntry(entryId) {
        this.$router.push({name: entryId, params: { id: entryId }});
      },
    },
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
