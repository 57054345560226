import Vue from 'vue';

export const DeleteEntryNotification = Vue.prototype.$swal.mixin({
  title: "Eliminar",
  text: "¿Desea eliminar este registro?",
  type: "warning",
  showCancelButton: true,
  confirmButtonColor: "#2EAE7F",
  cancelButtonColor: "#EB182B",
  confirmButtonText: "Sí, continuar",
  cancelButtonText: "No, cancelar",
  focusConfirm: false,
  focusCancel: true,
});

export const CommitEntryNotification = Vue.prototype.$swal.mixin({
  title: "Publicar",
  html: "<p>¿Desea publicar los últimos cambios realizados a este registro?</p><br/><i>*Esta acción es irreversible</i>",
  // type: "question",
  showCancelButton: true,
  confirmButtonColor: "#2EAE7F",
  cancelButtonColor: "#EB182B",
  confirmButtonText: "Sí, continuar",
  cancelButtonText: "No, cancelar",
  focusConfirm: false,
  focusCancel: true,
});

export const RestoreEntryNotification = Vue.prototype.$swal.mixin({
  title: "Restaurar",
  html: "<p>¿Está seguro que desea restaurar este registro?</p>",
  // type: "question",
  showCancelButton: true,
  confirmButtonColor: "#2EAE7F",
  cancelButtonColor: "#EB182B",
  confirmButtonText: "Sí, continuar",
  cancelButtonText: "No, cancelar",
  focusConfirm: false,
  focusCancel: true,
});
